.section {
  margin: 0 0 0.25cm;

  label {
    display: block;
  }

  input {
    margin: 0 0.5em;
  }
}
