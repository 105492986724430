import classNames from 'classnames';
import React from 'react';
import { Container } from './overflow';
import { useSize } from './utils';

import * as css from './list.module.less';
import * as typo from './typography.module.less';

export type ListItem = {
  name?: string;
  note?: string;
  span?: number;
  align?: boolean;
  visible?: boolean;
};

type ListItemWithIndex = ListItem & {
  idx: number;
};

function range(length: number): undefined[] {
  return new Array(length).fill(undefined);
}

function concat<T>(a: T[], b: T[]): T[] {
  return Array.prototype.concat.apply(a, b);
}

function normalizeItems(items: ListItem[]): [ListItemWithIndex[], boolean] {
  let i = 0;
  let skipped = false;

  const indexedItems: ListItemWithIndex[] = items
    .map(({ name, note, span = 1, align, visible }) => {
      const skip = (span % 2 === 0) && align !== false && (i % 2) > 0;
      skipped = skip || skipped;

      return concat(
        (skip ? [{ idx: ++i, name: '' }] : []) as ListItemWithIndex[],
        range(span).map((_, r) => (
          r > 0 ? { idx: ++i } : { idx: ++i, name, note, span, visible }
        )),
      );
    }).reduce((a, i) => concat(a, i), []);

  return [indexedItems, skipped];
}

function toCols(rows: ListItemWithIndex[]): [ListItemWithIndex, ListItemWithIndex][] {
  let idx = Math.ceil(rows.length / 2);

  while (idx < rows.length && rows[idx].name === undefined) {
    ++idx;
  }

  return rows.slice(0, idx).map((row, i) => [row, rows[i + idx] || { idx: i + idx + 1, name: '' }]);
}

export type ListProps = {
  items: ListItem[];
  children?: React.ReactNode;
};

export const List: React.FC<ListProps> = ({ items, children }) => {
  const [ref, size] = useSize<HTMLTableElement>();
  const double = size && size.width > 640;
  const [indexedItems, skipped] = normalizeItems(items);

  return (
    <>
      {skipped && children || undefined}
      <Container>
        <table ref={ref} className={css.container}>
          <colgroup className={double ? css.double : css.single}>
            <ColDefs />
            {double ? <ColDefs /> : undefined}
          </colgroup>
          <tbody>
            {double ? toCols(indexedItems).map(([a, b], i) => (
              <tr key={i}>
                <Item {...a} />
                <Item {...b} />
              </tr>
            )) : indexedItems.map((item, i) => (
              <tr key={i}>
                <Item {...item} />
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
    </>
  );
};

const ColDefs: React.FC = () => (
  <>
    <col className={css.idx} />
    <col className={css.name} />
    <col className={css.note} />
  </>
);

const Item: React.FC<ListItemWithIndex> = ({ idx, name, note, span, visible }) => (
  <>
    <th>{idx}</th>
    {name !== undefined ? (
      <>
        <td rowSpan={span}>{visible ? name : <i className={classNames(typo.help, typo['screen-only'])}>(spare)</i>}</td>
        <td rowSpan={span}>{visible ? <Note>{note}</Note> : null}</td>
      </>
    ) : undefined}
  </>
);

const Note: React.FC<{ children?: string }> = ({ children: text }) => {
  if (text && /^_(.+)_$/.test(text)) {
    return <i className={typo.help}>{text.slice(1, -1)}</i>
  } else {
    return <>{text}</>;
  }
};
