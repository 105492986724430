import React from 'react';
import { useData } from '../data';
import { Options, useOptions } from '../options';

import * as css from './panel.module.less';
import * as headers from '../header.module.less';

export const AdminOptions: React.FC = () => {
  const { options: definitions } = useData();
  const [options, setOptions] = useOptions();

  return (
    <div className={css.menu}>
      <h3 className={headers.section}>Config</h3>
      <Options definitions={definitions} values={options} onChange={setOptions} />
    </div>
  );
};
