band:
  name: Bára Zmeková
  engineer:
    name: Dan Kadera
    phone: +420 773 111 988
    email: me@subsonic.cz
options:
  - type: section
    label: 'Lineup:'
    items:
      - id: lineup
        type: checklist
        options:
          - { label: Lenka / vocal, value: lenka }
  - type: section
    label: Options
    items:
      - id: piano
        type: checkbox
        label: piano
        default: true
stage:
  areas:
    - id: left-arc
      type: arc
      x1: 0.075
      y1: 0.8
      $...:
        $if: { lineup: { $includes: lenka } }
        $then:
          x2: 0.175
          y2: 0.3
          r: 0.4
        $else:
          x2: 0.175
          y2: 0.425
          r: 0.6
    - id: back-line
      type: line
      x1: { $if: { piano: true }, $then: 0.6, $else: 0.45 }
      y1: 0.25
      x2: { $if: { piano: true }, $then: 0.725, $else: 0.7 }
      y2: 0.25
  scale: { $if: { piano: true }, $then: 0.7, $else: 0.8 }
musicians:
  - id: arturia
    name: ''
    instruments: arturia
    sockets: 2
    mic:
      left: 50%
      top: 0
      transform: rotate(-165deg) translate(0, 40%)
    placement:
      $if: piano
      $then: { x: 0.34, y: 0.35, a: 0 }
      $else: { x: 0.615, y: 0.7, a: 90 }
    minWidth: 120
    minHeight: 50
    inputs:
      - group: rhythm
        name: arturia
        count: 2
        note: 2x DI
  - id: piano
    name: Bára
    instruments: piano
    mic:
      left: 45%
      top: 100%
      transform: rotate(165deg) translate(0, 40%)
    placement: { x: 0.475, y: 0.55, a: 90 }
    minWidth: 140
    minHeight: 170
    inputs:
      - group: rhythm
        name: piano
        count: 2
        note: whichever mics & placement work best at the venue
    visible:
      $?: { piano: true }
    monitors:
      - iem: true
        group: bara
        stereo: true
        note: 1x XLR in (Y-split from 2x XLR provided at stagebox)
        $if: { piano: true }
  - id: ondra
    name: Ondra
    instruments: bass clarinet
    mic: true
    placement: left-arc
    inputs:
      - group: band
        name: bass clarinet
        note: own ATM350a clip-on mic
      - group: vocals
        name: vocal Ondra
        note: pref. Beta58
    monitors:
      - iem: true
        group: mono
        note: 1x XLR in
  - id: nina
    name: Nina
    instruments: violin
    mic: true
    placement: left-arc
    inputs:
      - group: band
        name: violin
        note: own dpa 4099
      - group: vocals
        name: vocal Nina
        note: own Beta58
    monitors:
      - iem: true
        group: mono
        note: 1x XLR in
  - id: lenka
    name: Lenka
    instruments: vocal
    mic: true
    placement: left-arc
    inputs:
      - group: vocals
        name: vocal Lenka
        note: pref. Beta58
    monitors:
      - $if: { lineup: { $includes: lenka } }
        iem: true
        group: dblpatch
        note: 1x XLR in
    visible: { $?: { lineup: { $includes: lenka } } }
  - id: michal
    name: Michal
    instruments: accdn / synth
    sockets: 1
    placement: back-line
    inputs:
      - group: band
        name: accordion
        note: 1x DI
      - group: rhythm
        name: synth
        note: 2x DI
        count: 2
    monitors:
      - group: mono
        note: floor monitor
        placement:
          $if: piano
          $then: { x: 0, y: 0.3, a: 0 }
          $else: { x: -0.05, y: 0.3 }
  - id: nord
    name: { $if: { piano: true }, $then: '', $else: Bára }
    instruments: nord ep
    mic:
      $if: { piano: true }
      $then: { left: '50%', top: -10, transform: 'rotate(180deg)' }
      $else: { left: '50%', top: '100%' }
    sockets: { $if: { piano: true }, $then: 1, $else: 2 }
    placement:
      $if: { piano: true }
      $then:
        x: 0.9
        y: 0.9
        a: 90
      $else:
        x: 0.5
        y: 0.9
    minWidth: 120
    minHeight: 60
    inputs:
      - group: keys
        name: nord bass
        note: 1x DI
        visible: false
      - group: keys
        name: nord ep
        note: 2x DI
        count: 2
      - group: vocals
        name: vocal Bára piano
        note: own KMS 104 Plus
        visible: { $: piano }
      - group: vocals
        name: { $if: { piano: true }, $then: vocal Bára arturia / nord, $else: vocal Bára }
        note: own EW365 G G3 wireless
    monitors:
      - iem: true
        group: bara
        stereo: true
        note: 1x XLR in (Y-split from 2x XLR provided at stagebox)
        $if: { piano: false }

  - id: pavel
    name: Pavel
    instruments: gtr / bass
    mic: true
    sockets: 2
    placement: back-line
    inputs:
      - group: bass
        name: bass amp
        note: 1x XLR
      - group: rhythm
        name: guitar
        note: 1x XLR
        visible: false
      - group: vocals
        name: vocal Pavel
        note: own Beta58
    monitors:
      - iem: true
        group: mono
        note: 1x XLR in
  - id: vik
    name: Viktor
    instruments: drum kit
    sockets: 2
    addon:
      name: spd-sx
      position:
        right: 10px
        top: 10px
    placement:
      $if: { piano: true }
      $then:
        x: 0.85
        y: 0.55
        a: 90
      $else:
        x: 0.85
        y: 0.65
        a: 80
    minWidth: 200
    minHeight: 170
    inputs:
      - group: drums
        name: kick
        note: pref. Beta52 or D6
      - group: drums
        name: snare 1 top
        note: pref. SM57
      - group: drums
        name: snare 1 bottom
        note: pref. e604
      - group: drums
        name: snare 2 top
        note: pref. SM57
      - group: drums
        name: snare 2 bottom
        note: pref. e604
      - group: drums
        name: hi tom
        note: pref. e604
      - group: drums
        name: floor tom
        note: pref. e604
      - group: drums
        name: hi hat
        note: pref. C451
      - group: drums
        name: overheads
        count: 2
        note: pref. C451
      - group: samples
        name: spd-sx
        note: 2x DI
        count: 2
    monitors:
      - iem: true
        group: stereo
        stereo: true
        note: 2x XLR in
inputList:
  groups:
    - drums
    - samples
    - bass
    - keys
    - rhythm
    - band
    - vocals
  note: >-
    Listed preferred mics are just that - preferred, not required.
    We can make it work with any reasonable alternatives.

monitorList:
  groups:
    - stereo
    - bara
    - mono
    - dblpatch
  note: >-
    The band uses wired in-ear monitors. Each musician has their own
    headphone amplifier belt pack on-stage.
mainOutputs:
  - name: front fill
    note: if needed
  - name: sub
    note: if possible
  - name: main l&r
    stereo: true
notes:
    - >-
      The band uses its own Midas M32C rack mixer with a DL32 stagebox.
    - >-
      The mixer is remotely controlled using a laptop at the FOH. Please
      provide a single CAT5 or CAT6 cable from the rack to the FOH.
    - >-
      The band engineer needs adequate table space at the FOH for a laptop and a
      controller plus some wiring - at least around 80x40cm.
    - >-
      Since there is no physical console at the FOH, outputs to the house PA
      system can only be provided from the DL32 on stage.
    - >-
      If your venue has a stereo wireless IEM transmitter and beltpack receiver
      we could borrow for Bara, it would be much appreciated!
