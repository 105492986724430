.container {
  background: #fff;
}

@media screen {
  .container {
    max-width: 21cm;
    margin: 1cm auto;
    padding: 1cm;
    box-shadow: 0 2px 10px #ddd;
  }
}

@media screen and (max-width: 640px) {
  .container {
    padding: 1cm 0.5cm;
  }
}

@media print {
  .container {
    padding: 1cm;
    page-break-after: always;
    break-after: page;
    page-break-inside: avoid;
    break-inside: avoid;

    &.screenOnly {
      display: none;
    }
  }
}
