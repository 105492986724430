import React, { useState } from 'react';
import { Gear, Print } from './icons';
import { AdminOptions } from './options';

import * as css from './panel.module.less';

export const AdminPanel: React.FC = () => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={css.container}>
      <button type="button" onClick={() => window.print()} title="Print">
        <Print size={30} />
      </button>
      <button type="button" onClick={() => setVisible(!visible)} title="Options" className={visible ? css.active : undefined}>
        <Gear size={30} />
      </button>
      {visible ? <AdminOptions /> : undefined}
    </div>
  );
};
