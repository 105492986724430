import React from 'react';
import {
  ChecklistOptionDefinition,
  OptionDefinition,
  PlainObject,
  getValue,
  setValue,
} from '../data';
import { Checkbox } from './checkbox';
import { Checklist } from './checklist';
import { Section } from './section';

export type OptionsProps = {
  definitions: OptionDefinition[];
  values: PlainObject;
  onChange: (values: PlainObject) => void;
};

export const Options: React.FC<OptionsProps> = ({ definitions, values, onChange }) => (
  <>
    {definitions.map((def, i) => {
      switch (def.type) {
        case 'section':
          return <Section key={`section${i}`} label={def.label} items={def.items} values={values} onChange={onChange} />;
        case 'checkbox':
          return <Checkbox key={def.id} label={def.label} checked={!!getValue(values, def.id)} onChange={(v) => onChange(setValue(values, def.id, v))} />;
        case 'checklist':
          return createChecklist(def, values, onChange);
      }
    })}
  </>
);

function createChecklist(def: ChecklistOptionDefinition, values: PlainObject, onChange: (values: PlainObject) => void): React.ReactElement {
  const items: string[] = getValue(values, def.id);
  const options = def.options.map(({ label, value, exclusive }) => ({ label, value, exclusive, checked: items.includes(value) }));
  return <Checklist key={def.id} options={options} onChange={(items) => onChange(setValue(values, def.id, items))} />;
}
