import React, { createContext, useContext } from 'react';
import { ZodError } from 'zod';
import { useOptions } from '../options';
import { useDerivedState } from '../utils';
import { expand } from './expand';
import { useRawData } from './loader';
import { Schema, schema } from './schema';
import { PlainObject } from './types';

const DataCtx = createContext<Schema>(undefined as any);
const DebugCtx = createContext<React.Dispatch<React.SetStateAction<Schema | { $error: Error }>>>(undefined as any);

export function useData(): Schema {
  return useContext(DataCtx);
}

export function useDataDebug() {
  return useContext(DebugCtx);
}

function expandData(data: PlainObject, options: PlainObject): Schema | { $error: Error } {
  try {
    return schema.parse(expand(data, options));
  } catch (e: any) {
    return { $error: e };
  }
}

function isError(data: Schema | { $error: Error }): data is { $error: Error } {
  return !data || !!(data as any).$error;
}

export const DataProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const src = useRawData();
  const [options] = useOptions();
  const [data, setData] = useDerivedState(expandData, src, options);

  if (isError(data)) {
    const err = data?.$error || new Error('Unknown error');
    console.error('Error processing data:', err instanceof ZodError ? err.issues : (err || 'Unknown error'));
    return <p>Error processing data</p>;
  }

  const title = `${data.band.name} - tech rider`;

  if (document.title !== title) {
    document.title = title;
  }

  return (
    <DataCtx.Provider value={data as any}>
      <DebugCtx.Provider value={setData}>
        {data ? children : undefined}
      </DebugCtx.Provider>
    </DataCtx.Provider>
  );
};
