band:
  name: Bára Zmeková
  engineer:
    name: Dan Kadera
    phone: +420 773 111 988
    email: me@subsonic.cz
options:
  - type: section
    label: 'Lineup:'
    items:
      - id: lineup
        type: checklist
        options:
          - { label: Viktor / drums, value: vik, default: true }
          - { label: Nina / violin, value: nina, default: true, exclusive: second }
          - { label: Michal / accordion, value: michal, default: true }
          - { label: Marci / sax, value: marci, exclusive: second }
          - { label: Pavel / bass, value: pavel }
          - { label: Ondra / basclarinet, value: ondra }
          # - { label: Oskar / trumpet, value: oskar }
  - type: section
    label: Options
    items:
      - id: iem
        type: checkbox
        label: IEMs
        default: true
      - id: iemstereo
        type: checklist
        options:
          - { label: IEM Viktor / drums stereo, value: vik, default: true }
          - { label: IEM Bara / keys stereo, value: bara, default: true }
      - id: dan
        type: checkbox
        label: band engineer
        default: true
      - id: synth
        type: checkbox
        label: synth
        default: true
      - id: guitar
        type: checkbox
        label: guitar
        default: true
      - id: piano
        type: checkbox
        label: piano
      - id: ondra-voc
        type: checkbox
        label: Ondra vocal
      #- id: large
      #  type: checkbox
      #  label: large stage
stage:
  areas:
    - id: back-line
      type: line
      x1: 0.325
      y1: 0.275
      x2: 0.575
      y2: 0.275
    - id: front-line
      type: line
      x1: 0.375
      y1: 0.6
      x2: 0.6
      y2: 0.6
    - id: others
      type: arc
      $...:
        $if:
          piano: true
        $then: { x1: 0.25, y1: 0.75, x2: 0.75, y2: 0.3, r: 0.5 }
        $else:
          $if:
            lineup:
              $includes: vik
          $then:
            $if:
              lineup.length:
                $gt: 4
            $then: { x1: 0.12, y1: 0.75, x2: 0.675, y2: 0.175, r: 0.54 }
            $else: { x1: 0.12, y1: 0.7, x2: 0.575, y2: 0.175, r: 0.54 }
          $else: { x1: 0.115, y1: 0.55, x2: 0.885, y2: 0.55, r: 0.54 }
  scale:
    $if: { piano: true }
    $then: 0.6
    $else:
      $if:
        $or:
          - lineup.length: { $gt: 6 }
          - $and:
              - lineup: { $includes: vik }
              - lineup.length: { $gt: 5 }
      $then: 0.825
      $else: 1
musicians:
  - id: piano
    name: Bára
    instruments: piano
    mic:
      left: 50%
      top: 100%
      transform: rotate(-180deg) translate(0, 40%)
    placement:
      $if: large
      $then: { x: 0.15, y: 0.6, a: 50 }
      $else: { x: 0.25, y: 0.35, a: 50 }
    minWidth: 200
    minHeight: 300
    inputs:
      - group: rhytm
        name: piano
        count: 2
        note:
          $if: { dan: true }
          $then: whichever mics & placement work best at the venue
    visible:
      $?: { piano: true }
  - id: ondra
    name: Ondra
    instruments: bass clarinet
    mic: { $: ondra-voc }
    placement: { $if: large, $then: back-line, $else: others }
    inputs:
      - group: band
        name: bass clarinet
        note: own ATM350a clip-on mic
      - group: vocals
        name: vocal Ondra
        note: pref. SM58
        visible: { $: ondra-voc }
    monitors:
      - iem: { $: iem }
        group: mono
        note:
          $if: { iem: true }
          $then: 1x XLR in
    visible: { $?: { lineup: { $includes: ondra } } }
#  - id: oskar
#    name: Oskar
#    instruments: trumpet
#    placement: { $if: large, $then: back-line, $else: others }
#    inputs:
#      - group: band
#        name: trumpet
#        note:
#          $if: { dan: true }
#          $then: own e608 clip-on mic
#    monitors:
#      - iem: { $: iem }
#        group: mono
#        note:
#          $if: { iem: true }
#          $then: 1x XLR in
#    visible: { $?: { lineup: { $includes: oskar } } }
  - id: marci
    name: Marci
    instruments: sax / flute
    mic: true
    placement: { $if: large, $then: back-line, $else: others }
    inputs:
      - group: band
        name: sax / flute
        note:
          $if: { dan: true }
          $then: own MTP 440 DM
      - group: vocals
        name: vocal Marci
        note:
          $if: { dan: true }
          $then: pref. SM58
    monitors:
      - iem: { $: iem }
        group: mono
        note:
          $if: { iem: true }
          $then: 1x XLR in
    $if: { lineup: { $includes: marci } }
#    visible: { $?: { lineup: { $includes: marci } } }
  - id: nina
    name: Nina
    instruments: violin
    mic: true
    placement: { $if: large, $then: front-line, $else: others }
    inputs:
      - group: band
        name: violin
        note: own dpa 4099
      - group: vocals
        name: vocal Nina
        note: own Beta58
    monitors:
      - iem: { $: iem }
        group: mono
        note:
          $if: { iem: true }
          $then: 1x XLR in
    $if: { $or: [{ lineup: { $includes: nina } }, { $not: { lineup: { $includes: marci } } }] }
    visible: { $?: { lineup: { $includes: nina } } }
  - id: dummy-spacer0
    name: '(spare)'
    inputs:
      - group: band
        name: '(spare)'
    visible: false
  - id: michal
    name: Michal
    instruments: { $if: synth, $then: accordion / synth, $else: accordion }
    placement: { $if: large, $then: front-line, $else: others }
    inputs:
      - group: band
        name: accordion
        note: 1x DI
      - group: rhytm
        name: synth
        note: 2x DI
        count: 2
        visible: { $?: { $and: [{ lineup: { $includes: michal } }, { synth: true }] } }
    monitors:
      - group: mono
        note: floor monitor
        placement:
          $if: large
          $then: { x: 0.1, y: 0.3, a: -30 }
          $else:
            $if:
              - lineup: { $includes: vik }
              - $not: { lineup: { $includes: pavel } }
              - lineup.length: { $gt: '4' }
            $then: { x: -0.05, y: 0.3 }

    visible: { $?: { lineup: { $includes: michal } } }
  - id: bara
    name: Bára
    instruments: nord stage 2 ex
    mic: true
    $...:
      $if: { lineup: { $includes: vik } }
      $then:
        sockets: 2
      $else:
        sockets: 3
        addon:
          name: laptop
          position:
            left: calc(100% - 10px)
            top: 10px
    placement:
      x: 0.5
      y: 0.85
    minWidth: 200
    inputs:
      - group: samples
        name: laptop
        note: 2x DI
        count: 2
        $if: { $not: { lineup: { $includes: vik } } }
      - group: keys
        name: nord bass
        note: 1x DI
      - group: keys
        name: nord ep
        note: 2x DI
        count: 2
      - group: vocals
        name: vocal Bára piano
        note: pref. KMS 104 Plus
        visible: { $: piano }
      - group: vocals
        name: { $if: { piano: true }, $then: vocal Bára nord, $else: vocal Bára }
        note: own KMS 104 Plus
    monitors:
      - $if: { iem: true }
        iem: true
        group: bara
        stereo: { $?: { iemstereo: { $includes: bara } } }
        note:
          $if: { iemstereo: { $includes: bara } }
          $then: 1x XLR in (Y-split from 2x XLR provided at stagebox)
          $else: 1x XLR in
      - $if: { iem: false }
        group: mono
        placement:
          x: 0.2
          y: 0
          a: -75
  - id: pavel
    name: Pavel
    instruments: { $if: { guitar: true }, $then: 'guitar / bass', $else: 'bass' }
    mic: true
    sockets: 1
    placement: { $if: large, $then: { x: 0.725, y: 0.35, a: 30 }, $else: others }
    inputs:
      - group: bass
        name: bass preamp
        note: 1x DI
      - group: rhytm
        name: guitar
        note: 2x XLR
        count: 2
        visible: { $?: { $and: [{ lineup: { $includes: pavel } }, { guitar: true }] } }
      - group: vocals
        name: vocal Pavel
        note: own Beta58
    monitors:
      - $if: { iem: true }
        iem: true
        group: mono
        note: 1x XLR in
      - $if: { iem: false }
        group: mono
        placement: { x: 0.075, y: 0.3, a: -20 }
    visible: { $?: { lineup: { $includes: pavel } } }
  - id: vik
    name: Viktor
    instruments: drum kit
    mic:
      left: 100%
      top: 50
      transform: rotate(-90deg) translate(-50%, -40%)
    sockets: 2
    addon:
      name: spd-sx
      position:
        right: 10px
        top: 10px
    placement:
      $if: { piano: true }
      $then:
        x: 0.875
        y: 0.65
        a: 85
      $else:
        x: 0.825
        y: 0.6
        a: 75
    $...:
      $if: { piano: true }
      $then:
        minWidth: 240
        minHeight: 240
      $else:
        minWidth: 200
        minHeight: 200
    inputs:
      - group: drums
        name: kick
        note:
          $if: { dan: true }
          $then: pref. Beta52 or D6
      - group: drums
        name: snare 1 top
        note:
          $if: { dan: true }
          $then: pref. SM57
      - group: drums
        name: snare 1 bottom
        note:
          $if: { dan: true }
          $then: pref. e604
      - group: drums
        name: snare 2 top
        note:
          $if: { dan: true }
          $then: pref. SM57
      - group: drums
        name: snare 2 bottom
        note:
          $if: { dan: true }
          $then: pref. e604
      - group: drums
        name: hi tom
        note:
          $if: { dan: true }
          $then: pref. e604
      - group: drums
        name: floor tom
        note:
          $if: { dan: true }
          $then: pref. e604
      - group: drums
        name: hi hat
        note:
          $if: { dan: true }
          $then: pref. C451
      - group: drums
        name: overheads
        count: 2
        note:
          $if: { dan: true }
          $then: pref. C451
      - $if: { lineup: { $includes: vik } }
        group: samples
        name: spd-sx
        note: 2x DI
        count: 2
      - group: vocals
        name: vocal Viktor
        note: own SM58
    monitors:
      - iem: true
        group: stereo
        stereo: { $?: { iemstereo: { $includes: vik } } }
        note:
          $if: { iemstereo: { $includes: vik } }
          $then: 2x XLR in
          $else: 1x XLR in
    visible: { $?: { lineup: { $includes: vik } } }
  - id: dummy-spacer1
    name: '(spare)'
    $if: { $not: { iemstereo: { $includes: vik } } }
    visible: false
    monitors:
      - group: stereo
  - id: dummy-spacer2
    name: '(spare)'
    $if: { $not: { iemstereo: { $includes: bara } } }
    visible: false
    monitors:
      - group: bara
inputList:
  groups:
    - drums
    - samples
    - bass
    - keys
    - rhytm
    - band
    - vocals
monitorList:
  groups:
    - stereo
    - bara
    - mono
  note:
    $if:
      iem: true
    $then: >-
      The band uses wired in-ear monitors. Each musician has their own
      headphone amplifier belt pack on-stage.
mainOutputs:
  - name: front fill
  - name: sub
  - name: main l&r
    stereo: true
notes:
  $if:
    dan: true
  $then:
    - $if: { lineup: { $includes: vik } }
      $then: >-
        The band uses its own Midas M32C rack mixer with a DL32 stagebox.
        Preferred placement of the rack is on stage right behind the drums.
      $else: The band uses its own Midas M32C rack mixer with a DL32 stagebox.
    - >-
      The mixer is remotely controlled using a laptop at the FOH. On smaller
      venues the mixer can be controlled wirelessly; on larger venues please
      provide a single CAT5 or CAT6 cable from the rack to the FOH.
    - >-
      The band engineer needs adequate table space at the FOH for a laptop and a
      controller plus some wiring - at least around 80x40cm.
    - >-
      Since there is no physical console at the FOH, master output can only be
      provided from the DL32 on stage.
    - >-
      If your venue has a stereo wireless IEM transmitter and beltpack receiver
      we could borrow for Bara, it would be much appreciated!
  $else:
    - >-
      The input list and output patch are arranged to work with a prepared
      session on the band's mixer, when it's present. Since it won't be present
      at your venue you can reorder the input list and output patch according
      to your needs and preference.
