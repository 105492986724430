.container {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: center center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #d2e2f1;
  white-space: nowrap;
}

.name {
  font-size: 10pt;
  font-weight: 900;
}

.instruments {
  font-size: 16pt;
}

.details {
  margin-top: 2px;
  font-size: 10pt;
  font-weight: 300;
}

.addon {
  position: absolute;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #000;
  background: #d2e2f1;
  font-size: 10pt;
}

.mic {
  position: absolute;
  left: 50%;
  top: 100%;
  width: 10px;
  height: 21px;
  transform-origin: center center;
  transform: translate(-50%, -60%);
}

.monitor {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 45px;
  transform-origin: center center;
}
