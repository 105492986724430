.help {
  color: #999;
}

@media print {
  .help {
    color: #333;
  }

  .screen-only {
    display: none;
  }
}
