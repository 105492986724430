import React from 'react';
import { OptionDefinition, PlainObject } from '../data';
import { Options } from './options';

import * as css from './options.module.less';

export type SectionProps = {
  label: string;
  items: OptionDefinition[];
  values: PlainObject;
  onChange: (values: PlainObject) => void;
};

export const Section: React.FC<SectionProps> = ({ label, items, values, onChange }) => (
  <>
    <h4>{label}</h4>
    <div className={css.section}>
      <Options definitions={items} values={values} onChange={onChange} />
    </div>
  </>
);
