import { ArcArea, LineArea, Placement, StageArea } from '../../data';
import { Matrix, rotate, translate, vector } from '../transform';

export function computeAreaPosition(area: StageArea, pos: number, width: number, height: number): Matrix {
  switch (area.type) {
    case 'line':
      return computeLinePosition(area, pos, width, height);
    case 'arc':
      return computeArcPosition(area, pos, width, height);
  }
}

export function computeFixedPosition({ x, y, a }: Placement, width: number, height: number, autoAngle: boolean = false): Matrix {
  const pos = translate(x * width, y * height);
  const angle = a !== undefined ? a : autoAngle ? Math.atan2(y, x) * 180 / Math.PI - 90 : undefined;
  return angle ? pos.multiplyBy(rotate(angle)) : pos;
}

function computeLinePosition({ x1, y1, x2, y2 }: LineArea, pos: number, width: number, height: number): Matrix {
  const a = Math.atan2(y2 - y1, x2 - x1);

  return rotate(a).translate(
    vector(x1 * width, y1 * height).add(
      vector((x2 - x1) * width, (y2 - y1) * height).multiplyBy(pos)
    ),
  );
}

function computeArcPosition({ x1, y1, x2, y2, r }: ArcArea, pos: number, width: number, height: number): Matrix {
  const { cx, cy, a1, a2 } = getArcProps(x1 * width, y1 * height, x2 * width, y2 * height, r * width);
  return translate(0, -r * width).rotate(a1 + (a2 - a1) * pos).translate(cx, cy);
}

type ArcProps = {
  cx: number;
  cy: number;
  a1: number;
  a2: number;
};

function getArcProps(x1: number, y1: number, x2: number, y2: number, r: number): ArcProps {
  const xa = (x2 - x1) / 2;
  const ya = (y2 - y1) / 2;
  const x0 = x1 + xa;
  const y0 = y1 + ya;
  const a = Math.sqrt(xa * xa + ya * ya);
  const b = Math.sqrt(r * r - a * a);
  const cx = x0 - b * ya / a;
  const cy = y0 + b * xa / a;

  const a1 = Math.atan2(cy - y1, cx - x1) * 180 / Math.PI - 90;
  const a2 = Math.atan2(cy - y2, cx - x2) * 180 / Math.PI - 90;

  return { cx, cy, a1, a2 };
}
