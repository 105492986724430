import React from 'react';
import { Matrix } from '../transform';

import * as css from './musician.module.less';

export type MonitorProps = {
  transform: Matrix;
  number: number;
  children?: never;
};

export const Monitor: React.FC<MonitorProps> = ({ transform, number }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="45"
    viewBox="0 0 80 45"
    className={css.monitor}
    style={{ transform: `translate(-50%, -50%) ${transform.toCSS()}` }}>
    <path d="M 0.5 0.5 h 79 v 29 l -20 15 h -39 l -20 -15 Z"
      stroke="#000"
      fill="#e1e1e1" />
    <text fill="#000" x="40" y="30" fontFamily="Source Sans Pro" fontSize="26" textAnchor="middle">{number}</text>
  </svg>
);
