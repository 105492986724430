import { FC } from 'react';
import { AdminPanel } from './admin';
import { DataLoader, DataProvider } from './data';
import { Header } from './header';
import { Monitors } from './monitors';
import { InputList } from './inputList';
import { Notes } from './notes';
import { OptionsProvider } from './options';
import { Page } from './page';
import { Stageplan } from './stageplan';

import './main.less';

export const Main: FC = () => (
  <DataLoader>
    <OptionsProvider>
      <DataProvider>
        <Page>
          <Header />
          <Stageplan />
          <Notes />
        </Page>
        <Page>
          <Header printOnly />
          <InputList />
          <Monitors />
        </Page>
        <AdminPanel />
      </DataProvider>
    </OptionsProvider>
  </DataLoader>
);
