@shadow-width: 12px;

.container {
  position: relative;
  overflow: hidden;

  .content {
    overflow-x: auto;

    & > :first-child {
      float: left;
      width: 100%;
    }

    &::after {
      display: block;
      height: 0;
      clear: left;
      content: '';
    }
  }

  &::before, &::after {
    position: absolute;
    display: block;
    width: @shadow-width;
    height: 100%;
    top: 0;
    z-index: 2;
    content: '';
    transform-origin: left top;
    transition: transform 0.15s ease-in;
  }

  &::before {
    left: 0;
    transform: translateX(-@shadow-width);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    right: 0;
    transform: translateX(@shadow-width);
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.shadow-left::before, &.shadow-right::after {
    transform: translateX(0);
  }
}

@media screen and (max-width: 640px) {
  .container.wide {
    margin-left: -0.5cm;
    margin-right: -0.5cm;
  }
}

@media print {
  .container {
    &::before, &::after {
      display: none;
    }
  }
}
