import React, { CSSProperties } from 'react';
import { MusicianAddon, Placement } from '../../data';
import { useStageplanSize } from '../container';
import { Matrix, unity } from '../transform';
import { Mic } from './mic';
import { Monitor } from './monitor';
import { computeFixedPosition } from './utils';

import * as css from './musician.module.less';

export type MonitorOptions = {
  number: number;
  iem?: boolean;
  placement?: Placement;
};

export type MusicianProps = {
  name?: string;
  instruments?: string;
  mic?: boolean | CSSProperties;
  sockets?: number;
  monitors?: MonitorOptions[];
  addon?: MusicianAddon;
  minWidth?: number;
  minHeight?: number;
  transform?: Matrix;
  children?: never;
};

const defaultMonitorPlacement: Placement = {
  x: 0,
  y: 0.3,
};

export const Musician: React.FC<MusicianProps> = ({
  name,
  instruments,
  mic,
  sockets,
  monitors = [],
  addon,
  minWidth = 100,
  minHeight = 90,
  transform = unity(),
}) => {
  const stage = useStageplanSize();
  const details: string[] = [];

  sockets && details.push(`${sockets}x 230V`);

  const [iems, floor] = monitors.reduce(
    ([im, fm], m) => (m.iem ? [im.concat(m), fm] : [im, fm.concat(m)]),
    [[], []] as [MonitorOptions[], MonitorOptions[]],
  );

  iems.length && details.push(`IEM ${iems.map((m) => m.number).join('+')}`);

  return (
    <div className={css.container} style={{ minWidth, minHeight, transform: `translate(-50%, -50%) ${transform.toCSS()}` }}>
      {name ? <div className={css.name}>{name}</div> : undefined}
      {instruments ? <div className={css.instruments}>{instruments}</div> : undefined}
      {details.length > 0 ? <div className={css.details}>{details.join(', ')}</div> : undefined}
      {mic ? <Mic style={typeof mic === 'object' ? mic : undefined} /> : undefined}
      {addon ? <div className={css.addon} style={addon.position}>{addon.name}</div> : undefined}
      {floor.map(({ number, placement = defaultMonitorPlacement }) => (
        <Monitor key={number}
          transform={computeFixedPosition(placement, stage.width, stage.height, true)}
          number={number} />
      ))}
    </div>
  );
};
