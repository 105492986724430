.container {
  position: relative;
  min-width: 15cm;
  margin: 1cm 0 0.5cm;

  &::before {
    display: block;
    width: 0;
    height: 0;
    padding-bottom: 45%;
    content: '';
  }
}

.footer {
  margin-top: 0.5cm;
  margin-bottom: 3em;
  text-align: center;
  font: 300 13pt/1.15 'Source Sans Pro', sans-serif;
  color: #666;
}

.debugDot {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  transform-origin: center center;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #f00;
}
