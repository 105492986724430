import React from 'react';
import { MusicianOptions, sortByGroup, useData } from './data';
import { List, ListItem } from './list';

import * as css from './header.module.less';

function flatten(musicians: MusicianOptions[]): (ListItem & { group: string })[] {
  return musicians
    .filter((m) => !!m.inputs && m.inputs.length)
    .map(({ inputs, visible: defaultVisible }) => (
      inputs!.map(({ group, name, note, align, count, visible }) => ({
        group,
        name,
        note,
        align,
        span: count,
        visible: typeof visible === 'boolean' ? visible : defaultVisible
      }))
    ))
    .reduce((a, i) => a.concat(i), []);
}

export const InputList: React.FC = () => {
  const { musicians, inputList: options } = useData();
  const items = options && options.groups && options.groups.length
    ? sortByGroup(flatten(musicians), options.groups)
    : flatten(musicians);

  return (
    <>
      <h3 className={css.section}>Input Patch</h3>
      {options?.note ? <p>{options.note}</p> : undefined}
      <List items={items} />
    </>
  );
};
