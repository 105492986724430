import React from 'react';
import { useData, sortByGroup } from './data';
import { List } from './list';

import * as css from './header.module.less';

export const Monitors: React.FC = () => {
  const { musicians, monitorList: options, mainOutputs } = useData();

  if (!options) {
    return null;
  }

  const monitors = musicians
    .filter((m) => !!m.monitors && m.monitors.length)
    .map(({ name, instruments, monitors, visible }) => monitors!.map(({ group, note, stereo, iem }) => ({
      name: [name, instruments].filter(v => v !== undefined).join(' / '),
      group,
      note: formatNote(iem, note),
      span: stereo ? 2 : 1,
      visible,
    })))
    .reduce((list, items) => list.concat(items), []);

  const outputs = (mainOutputs ?? [])
    .map(({ name, stereo, note }) => ({
      name,
      group: '#outputs',
      note,
      span: stereo ? 2 : 1,
      visible: true,
    }));

  const channels = [...monitors, ...outputs].reduce((n, o) => n + o.span, 0);
  const fill = channels < 16 && outputs.length
    ? [{ name: '', group: '#fill', span: 16 - channels, visible: false }]
    : [];

  const items = [
    ...monitors,
    ...fill,
    ...outputs,
  ];

  const groups = [
    ...(options && options.groups && options.groups.length ? options.groups : []),
    '#fill',
    '#outputs',
  ];

  const sortedItems = sortByGroup(items, groups);

  return (
    <>
      <h3 className={css.section}>Output Patch</h3>
      {options.note ? <p>{options.note}</p> : undefined}
      <List items={sortedItems}>
        <p>Empty outputs left out to accommodate consoles which can only link odd-even bus pairs.</p>
      </List>
    </>
  );
};

function formatNote(iem?: boolean, note?: string) : string | undefined {
  const parts: string[] = [];
  iem && parts.push('IEM');
  note && parts.push(note);
  return parts.length ? parts.join('; ') : undefined;
}
