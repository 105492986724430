import { PlainObject } from './types';

export function getValue(params: PlainObject, path: string, def?: any): any {
  const keys = path.split(/\./g);
  let cursor = params;

  for (const key of keys) {
    cursor = cursor[key];

    if (cursor === undefined || cursor === null) {
      return def;
    }
  }

  return cursor;
}

export function setValue(params: PlainObject, path: string, value: any, mutate: boolean = false): PlainObject {
  const keys = path.split(/\./g);
  const last = keys.pop()!;
  const result = mutate ? params : clone(params);
  let cursor = result;

  for (const key of keys) {
    if (!(key in cursor)) {
      cursor[key] = {};
    }

    cursor = cursor[key];
  }

  cursor[last] = value;
  return result;
}

export function clone(o: any): any {
  if (Array.isArray(o)) {
    return o.map(clone);
  } else if (typeof o === 'object' && o !== null) {
    const result: PlainObject = {};

    for (const [k, v] of Object.entries(o)) {
      result[k] = clone(v);
    }

    return result;
  } else {
    return o;
  }
}
