.container {
  margin: 0 0 10px;
  text-align: center;

  &.print-only {
    display: none;
  }
}

.title {
  margin: 0;
  font: 900 26pt/1.15 'Source Sans Pro', sans-serif;
}

.subtitle {
  margin: 0;
  font: 300 15pt/1.15 'Source Sans Pro', sans-serif;
  color: #666;
}

.section {
  margin: 0.35cm 0 0.375cm;
  font: 900 14pt/1.15 'Source Sans Pro', sans-serif;
  color: #333;
}

@media print {
  .container.print-only {
    display: block;
  }
}
