import React, { createContext, useContext } from 'react';
import { Container as Overflow } from '../overflow';
import { Size, useSize } from '../utils';

import * as css from './stageplan.module.less';

const Ctx = createContext<Size>(undefined as any);

export function useStageplanSize(): Size {
  return useContext(Ctx);
}

export const Container: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [ref, size] = useSize<HTMLDivElement>();

  return (
    <>
      <Overflow wide>
        <div ref={ref} className={css.container}>
          {size ? <Ctx.Provider value={size}>{children}</Ctx.Provider> : undefined}
        </div>
      </Overflow>
      <div className={css.footer}>↓ audience ↓</div>
    </>
  );
};
