import classNames from 'classnames';
import React from 'react';

import * as css from './page.module.less';

export type PageProps = {
  screenOnly?: boolean;
  children?: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({ screenOnly, children }) => (
  <div className={classNames(css.container, screenOnly && css.screenOnly)}>
    {children}
  </div>
);
