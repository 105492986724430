import React from 'react';
import { Checkbox } from './checkbox';

export type ChecklistOption = {
  label: string;
  value: string;
  checked: boolean;
  exclusive?: string;
};

export type ChecklistProps = {
  options: ChecklistOption[];
  onChange: (values: string[]) => void;
  children?: never;
};

export const Checklist: React.FC<ChecklistProps> = ({ options, onChange }) => (
  <div>
    {options.map(({ label, value, checked, exclusive }) => (
      <Checkbox key={value} label={label} checked={checked} onChange={(ch) => onChange(apply(options, value, ch, exclusive))} />
    ))}
  </div>
);

function apply(options: ChecklistOption[], value: string, checked: boolean, exclusive?: string): string[] {
  return options
    .filter((o) => o.value === value ? checked : (checked && exclusive && o.exclusive === exclusive ? false : o.checked))
    .map((o) => o.value);
}
