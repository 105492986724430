type GroupItem = {
  group: string;
};

export function sortByGroup<T extends GroupItem>(items: T[], groups: string[]): T[] {
  return items
    .map((item, idx) => ({ grp: groups.indexOf(item.group), idx, item }))
    .sort((a, b) => (a.grp - b.grp || a.idx - b.idx))
    .map(({ item }) => item);
}
