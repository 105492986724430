import React, { CSSProperties } from 'react';

import * as css from './musician.module.less';

export const Mic: React.FC<{ style?: CSSProperties }> = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="21" viewBox="0 0 10 21" className={css.mic} style={style}>
    <circle cx="5" cy="5" r="5" stroke="none" fill="#333" />
    <path d="M 2 11 h 6 L 7 21 h -4 Z" stroke="none" fill="#333" />
  </svg>
);
