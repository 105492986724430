import React, { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import * as css from './overflow.module.less';

type State = { left: boolean, right: boolean };

export type ContainerProps = {
  wide?: boolean;
  children?: React.ReactNode;
};

export const Container: React.FC<ContainerProps> = ({ wide, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<State | undefined>(undefined);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    } else if (!state) {
      handleScroll();
      return;
    }

    ref.current.addEventListener('scroll', handleScroll, { passive: false });
    return () => ref.current?.removeEventListener('scroll', handleScroll);

    function handleScroll() {
      if (!ref.current) {
        return;
      }

      const left = ref.current.scrollLeft > 0;
      const right = ref.current.scrollLeft < ref.current.scrollWidth - ref.current.clientWidth;

      if (!state || left !== state.left || right !== state.right) {
        setState({ left, right });
      }
    }
  }, [ref, state, setState]);

  return (
    <div className={classNames(css.container, wide && css.wide, state?.left && css['shadow-left'], state?.right && css['shadow-right'])}>
      <div ref={ref} className={css.content}>
        {children}
      </div>
    </div>
  );
};
