.container {
  position: fixed;
  bottom: 0.25cm;
  right: 0.25cm;

  button {
    margin: 0 0.25cm;
    padding: 0.25cm;
    border: none;
    background: transparent;
    font: inherit;
    color: #666;
    outline: none;
    cursor: pointer;

    svg path {
      stroke: currentColor;
      fill: currentColor;
    }

    &:hover, &.active {
      color: #333;
    }
  }

  .menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    min-width: 15em;
    padding: 0.25cm 0.5cm;
    background: #fff;
    box-shadow: 0 5px 10px #aaa;

    h3 {
      margin-top: 0.25cm;
    }

    h4 {
      margin: 0.5cm 0 0.25cm;
    }
  }
}

@media print {
  .container {
    display: none;
  }
}
