import { evaluateConditional } from './conditionals';
import { PlainObject, expr } from './types';
import { getValue } from './utils';

export function expand(data: PlainObject, options: PlainObject): PlainObject {
  return expandNode(data, options);
}

function expandNode(value: any, options: PlainObject): any {
  if (value === null) {
    return value;
  } else if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.map((v) => expandNode(v, options)).filter((v) => v !== undefined && v !== null);
    } else if (expr.isConditional(value)) {
      return expandNode(evaluateConditional(value, options), options);
    } else if (expr.isExpand(value)) {
      return getValue(options, value.$);
    } else if (expr.isEvaluation(value)) {
      return evaluateConditional({ $if: value['$?'], $then: true, $else: false }, options);
    } else {
      return expandObject(value, options);
    }
  } else {
    return value;
  }
}

function expandObject(object: PlainObject, options: PlainObject): any {
  const result: PlainObject = {};

  for (const [key, value] of Object.entries(object)) {
    const expandedValue = expandNode(value, options);

    if (key === '$...') {
      if (typeof expandedValue === 'object' && expandedValue !== null) {
        Object.assign(result, expandedValue);
      }
    } else {
      result[key] = expandedValue;
    }
  }

  return result;
}
