import React, { createContext, useContext, useState } from 'react';
import { OptionDefinition, PlainObject, useRawData } from '../data';
import { useDerivedValue } from '../utils';
import { loadState, saveState } from './utils';

type OptionsCtxData = [PlainObject, (options: PlainObject) => void];

const OptionsCtx = createContext<OptionsCtxData>(undefined as any);

export function useOptions(): OptionsCtxData {
  return useContext(OptionsCtx);
}

function createSaveHandler(
  setOptions: React.Dispatch<React.SetStateAction<PlainObject>>,
  defs: OptionDefinition[],
) {
  return (options: PlainObject) => {
    saveState(defs, options);
    setOptions(options);
  };
}

export const OptionsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const src = useRawData();
  const defaults = useDerivedValue(loadState, src.options);
  const [options, setOptions] = useState<PlainObject>(defaults);
  const saveOptions = useDerivedValue(createSaveHandler, setOptions, src.options);

  return (
    <OptionsCtx.Provider value={[options, saveOptions]}>
      {children}
    </OptionsCtx.Provider>
  );
};
