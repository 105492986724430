export type PlainObject = Record<string, any>;

export type ComparableValue = string | number;
export type Value = ComparableValue | boolean;

export type FilterEq = { $eq: Value[] | Value | null };
export type FilterNeq = { $neq: Value[] | Value | null };
export type FilterLt = { $lt: ComparableValue };
export type FilterLte = { $lte: ComparableValue };
export type FilterGt = { $gt: ComparableValue };
export type FilterGte = { $gte: ComparableValue };
export type FilterIncludes = { $includes: ComparableValue };
export type FilterExpr = { [path: string]: Filter | Value | Value[] | null } | string;

export type Filter = FilterEq | FilterNeq | FilterLt | FilterLte | FilterGt | FilterGte | FilterIncludes;

export type OperatorAnd = { $and: (Operator | FilterExpr)[] };
export type OperatorOr = { $or: (Operator | FilterExpr)[] };
export type OperatorNot = { $not: Operator | FilterExpr };

export type Operator = OperatorAnd | OperatorOr | OperatorNot;

export type Conditions = Operator | FilterExpr | FilterExpr[];

export type Conditional = {
  "$if": Conditions;
  "$then"?: any;
  "$else"?: any;
  [key: string]: any;
};

export type Expand = {
  "$": string;
};

export type Evaluation = {
  "$?": Conditions;
};

export const expr = {
  isFilter(value: Filter | Value | Value[] | null): value is Filter {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  },

  isFilterEq(value: Filter): value is FilterEq {
    return '$eq' in value;
  },

  isFilterNeq(value: Filter): value is FilterNeq {
    return '$neq' in value;
  },

  isFilterLt(value: Filter): value is FilterLt {
    return '$lt' in value;
  },

  isFilterLte(value: Filter): value is FilterLte {
    return '$lte' in value;
  },

  isFilterGt(value: Filter): value is FilterGt {
    return '$gt' in value;
  },

  isFilterGte(value: Filter): value is FilterGte {
    return '$gte' in value;
  },

  isFilterIncludes(value: Filter): value is FilterIncludes {
    return '$includes' in value;
  },

  isOperatorAnd(value: Operator | FilterExpr): value is OperatorAnd {
    return typeof value === 'object' && '$and' in value;
  },

  isOperatorOr(value: Operator | FilterExpr): value is OperatorOr {
    return typeof value === 'object' && '$or' in value;
  },

  isOperatorNot(value: Operator | FilterExpr): value is OperatorNot {
    return typeof value === 'object' && '$not' in value;
  },

  isConditional(value: any): value is Conditional {
    return typeof value === 'object' && '$if' in value;
  },

  isExpand(value: any): value is Expand {
    return typeof value === 'object' && '$' in value;
  },

  isEvaluation(value: any): value is Evaluation {
    return typeof value === 'object' && '$?' in value;
  }
};
