import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export type Size = {
  width: number;
  height: number;
};

export function useSize<E extends HTMLElement = HTMLElement>(): [RefObject<E>, Size | undefined] {
  const ref = useRef<E>(null);
  const [size, setSize] = useState<Size>();

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const handler = () => {
      if (!ref.current) {
        return;
      }

      const { width, height } = ref.current!.getBoundingClientRect();
      setSize({ width, height });
    };

    window.matchMedia('print').addEventListener('change', handler);

    const { width, height } = ref.current.getBoundingClientRect();
    setSize({ width, height });

    return () => window.matchMedia('print').removeEventListener('change', handler);
  }, [ref, setSize]);

  useResizeObserver(ref, ({ contentRect: { width, height } }) => setSize({ width, height }));

  return [ref, size];
}
