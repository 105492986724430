import { Conditions, PlainObject, Filter, Value, ComparableValue, Conditional, expr } from './types';
import { getValue } from './utils';

export function evaluateConditional({ $if, $then, $else, ...o }: Conditional, params: PlainObject): any {
  if (match($if, params)) {
    return $then !== undefined ? $then : o;
  } else {
    return $else;
  }
}

function match(conditions: Conditions, params: PlainObject): boolean {
  const cond = Array.isArray(conditions) ? { $and: conditions } : conditions;

  if (typeof cond === 'string') {
    return test(cond, true, params);
  } else if (expr.isOperatorAnd(cond)) {
    return cond.$and.every((c) => match(c, params));
  } else if (expr.isOperatorOr(cond)) {
    return cond.$or.some((c) => match(c, params));
  } else if (expr.isOperatorNot(cond)) {
    return !match(cond.$not, params);
  }

  return Object.entries(cond).every(([path, filter]) => test(path, filter, params));
}

function test(
  path: string,
  filterOrValue: Filter | Value | Value[] | null,
  params: PlainObject,
): boolean {
  const value = getValue(params, path);
  const filter = normalizeFilter(filterOrValue);

  if (expr.isFilterEq(filter)) {
    if (Array.isArray(filter.$eq)) {
      return !Array.isArray(value) && value !== null && filter.$eq.includes(value);
    } else {
      return value === filter.$eq;
    }
  } else if (expr.isFilterNeq(filter)) {
    if (Array.isArray(filter.$neq)) {
      return Array.isArray(value) || value === null || !filter.$neq.includes(value);
    } else {
      return value !== filter.$neq;
    }
  } else if (expr.isFilterLt(filter)) {
    return isComparable(value) ? value < filter.$lt : false;
  } else if (expr.isFilterLte(filter)) {
    return isComparable(value) ? value <= filter.$lte : false;
  } else if (expr.isFilterGt(filter)) {
    return isComparable(value) ? value > filter.$gt : false;
  } else if (expr.isFilterGte(filter)) {
    return isComparable(value) ? value >= filter.$gte : false;
  } else if (expr.isFilterIncludes(filter)) {
    return Array.isArray(value) || typeof value === 'string'
      ? value.includes(filter.$includes as any)
      : false;
  } else {
    throw new Error('This should be unreachable');
  }
}

function normalizeFilter(filter: Filter | Value | Value[] | null): Filter {
  return expr.isFilter(filter)
    ? filter
    : { $eq: filter };
}

function isComparable(value: Value[] | Value | null): value is ComparableValue {
  return ['string', 'number'].includes(typeof value);
}
