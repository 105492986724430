import React from 'react';
import { MusicianMonitor, MusicianOptions, StageArea, useData, sortByGroup } from '../data';
import { useOptions } from '../options';
import { computeAreaPosition, computeFixedPosition, Musician } from './objects';
import { useStageplanSize } from './container';
import { Matrix, scale, unity } from './transform';

import * as css from './stageplan.module.less';

export const Content: React.FC = () => {
  const { width, height } = useStageplanSize();
  const { stage, musicians, monitorList } = useData();
  const scaleToScreen = width < 795 ? scale(width / 795) : unity();
  const scaleToContent = stage?.scale ? scale(stage.scale) : unity();
  const xform = scaleToScreen.multiplyBy(scaleToContent);
  const [areas, fixedMusicians] = getAreaMap(musicians, stage?.areas);
  const monitors = getMonitorMap(musicians, monitorList?.groups);
  const [{ __raw: { debug } }] = useOptions();

  return (
    <>
      {areas.map(({ musicians, ...area }) => musicians.map((m, i) => (
        <Musician
          key={m.id}
          name={m.name}
          instruments={m.instruments}
          mic={m.mic}
          sockets={m.sockets}
          monitors={monitors[m.id]}
          addon={m.addon}
          minWidth={m.minWidth}
          minHeight={m.minHeight}
          transform={computeAreaPosition(area, (i + 1) / (musicians.length + 1), width, height).multiplyBy(xform)}
          />
      )))}
      {fixedMusicians.map((m) => (
        <Musician
          key={m.id}
          name={m.name}
          instruments={m.instruments}
          mic={m.mic}
          sockets={m.sockets}
          monitors={monitors[m.id]}
          addon={m.addon}
          minWidth={m.minWidth}
          minHeight={m.minHeight}
          transform={computeFixedPosition(m.placement as any, width, height).multiplyBy(xform)}
        />
      ))}
      {debug === 'areas' && areas.map(({ musicians, ...area }) => [...new Array(17).keys()].map((i) => (
        <Dot key={i} transform={computeAreaPosition(area, i / 16, width, height).multiplyBy(xform)} />
      )))}
    </>
  );
};

type AreaWithMusicians = StageArea & {
  musicians: MusicianOptions[];
};

function getAreaMap(musicians: MusicianOptions[], areas?: StageArea[]): [AreaWithMusicians[], MusicianOptions[]] {
  if (!areas || !areas.length) {
    return [[], musicians.filter((m) => m.visible && !!m.placement)];
  }

  const map: Record<string, AreaWithMusicians> = {};

  for (const area of areas) {
    map[area.id] = {
      ...area,
      musicians: [],
    };
  }

  const fixed: MusicianOptions[] = [];

  for (const musician of musicians) {
    if (!musician.visible) {
      continue;
    } else if (typeof musician.placement === 'string') {
      map[musician.placement].musicians.push(musician);
    } else if (musician.placement) {
      fixed.push(musician);
    }
  }

  return [Object.values(map), fixed];
}

type MonitorWithId = MusicianMonitor & {
  id: string;
  group: string;
};

type Monitor = MusicianMonitor & {
  number: number;
};

function getMonitorMap(musicians: MusicianOptions[], groups?: string[]): Record<string, Monitor[]> {
  const monitors = musicians
    .filter((m) => m.monitors && m.monitors.length)
    .map(({ id, monitors, visible: defaultVisible }) => (
      monitors!.map(({ visible, ...m }) => ({
        id,
        visible: typeof visible === 'boolean' ? visible : defaultVisible,
        ...m
      }))
    ))
    .reduce((list, items) => list.concat(items as MonitorWithId[]), [] as MonitorWithId[]);

  const sortedMonitors = groups && groups.length
    ? sortByGroup(monitors, groups)
    : monitors;

  const map: Record<string, Monitor[]> = {};
  let i = 0;

  for (const monitor of sortedMonitors) {
    if (!monitor.visible) {
      ++i;
      monitor.stereo && ++i;
      continue;
    }

    map[monitor.id] || (map[monitor.id] = []);
    map[monitor.id].push({ number: ++i, ...monitor });
    monitor.stereo && map[monitor.id].push({ number: ++i, ...monitor });
  }

  return map;
}

type DotProps = {
  transform: Matrix;
};

const Dot: React.FC<DotProps> = ({ transform }) => (
  <div className={css.debugDot} style={{ transform: `translate(-50%, -50%) ${transform.toCSS()}` }} />
);
