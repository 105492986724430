import React from 'react';

export type CheckboxProps = {
  label: string;
  checked: boolean;
  readOnly?: boolean;
  onChange: (checked: boolean) => void;
  children?: never;
};

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, readOnly, onChange }) => (
  <label>
    <input type="checkbox"  checked={checked} readOnly={readOnly} onChange={(evt) => onChange(evt.target.checked)} />
    {label}
  </label>
);
