import { getValue, OptionDefinition, PlainObject, setValue } from '../data';

export function saveState(defs: OptionDefinition[], { __raw, ...options }: PlainObject): void {
  const params = serialize(defs, options);

  if (window.history && window.history.replaceState) {
    window.history.replaceState(
      null,
      document.title,
      document.location.href.replace(/(?:#.*)?$/, `#${params}`),
    );
  } else {
    document.location.hash = params;
  }
}

export function loadState(defs: OptionDefinition[]): PlainObject {
  const serialized = document.location.hash.replace(/^#/, '');
  const params = serialized ? parse(serialized) : {};

  return {
    ...deserialize({}, defs, params),
    __raw: params,
  };
}

function serialize(defs: OptionDefinition[], options: PlainObject): string {
  const params: string[] = [];

  for (const def of defs) {
    if (def.type === 'section') {
      const values = serialize(def.items, options);
      values && params.push(values);
    } else if (def.type === 'checkbox') {
      const value = getValue(options, def.id);

      if (value !== undefined && value !== (def.default ?? false)) {
        params.push(`${value ? '' : '!'}${encodeURIComponent(def.id)}`);
      }
    } else if (def.type === 'checklist') {
      const value = getValue(options, def.id);

      if (value && !isSameArray(value, def.options.filter((o) => o.default).map((o) => o.value))) {
        params.push(`${encodeURIComponent(def.id)}=${value.map(encodeURIComponent).join('+')}`);
      }
    } else {
      throw new Error('Unsupported option type');
    }
  }

  return params.join('&');
}

function deserialize(options: PlainObject, defs: OptionDefinition[], params: PlainObject): PlainObject {
  for (const def of defs) {
    if (def.type === 'section') {
      deserialize(options, def.items, params);
    } else if (def.type === 'checkbox') {
      const value = getValue(params, def.id, def.default ?? false);
      setValue(options, def.id, value, true);
    } else if (def.type === 'checklist') {
      const value = getValue(params, def.id, def.options.filter((o) => o.default).map((o) => o.value));
      setValue(options, def.id, Array.isArray(value) ? value : [value], true);
    } else {
      throw new Error('Unsupported option type');
    }
  }

  return options;
}

function parse(params: string): PlainObject {
  const options: PlainObject = {};
  const pairs = params.split(/&/g).map((p) => p.split(/=/));

  for (const [key, value] of pairs) {
    const path = key.replace(/^!/, '');
    const not = /^!/.test(key);
    setValue(options, decodeURIComponent(path), value === undefined ? !not : decode(value), true);
  }

  return options;
}

function decode(value: string): any {
  if (value.includes('+')) {
    return value.split(/\+/g).map(decodeURIComponent);
  } else if (value.includes(',')) { // legacy
    return value.split(/,/g).map(decodeURIComponent);
  } else {
    return decodeURIComponent(value);
  }
}

function isSameArray(a: any[], b: any[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0, n = a.length; i < n; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}
