export class Matrix {
  readonly a: number;
  readonly b: number;
  readonly c: number;
  readonly d: number;
  readonly tx: number;
  readonly ty: number;

  constructor(a: number, b: number, c: number, d: number, tx: number, ty: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.d = d;
    this.tx = tx;
    this.ty = ty;
  }

  multiplyBy(m: Matrix): Matrix {
    return new Matrix(
      this.a * m.a + this.c * m.b,
      this.b * m.a + this.d * m.b,
      this.a * m.c + this.c * m.d,
      this.b * m.c + this.d * m.d,
      this.a * m.tx + this.c * m.ty + this.tx,
      this.b * m.tx + this.d * m.ty + this.ty,
    );
  }

  translate(v: Vector): Matrix;
  translate(x: number, y: number): Matrix;
  translate(x: any, y?: any): Matrix {
    return translate(x, y).multiplyBy(this);
  }

  rotate(a: number, origin?: Vector): Matrix {
    return rotate(a, origin).multiplyBy(this);
  }

  scale(a: number, b: number = a): Matrix {
    return scale(a, b).multiplyBy(this);
  }

  toString(): string {
    return `(${[
      [this.a, this.c, this.tx].join(', '),
      [this.b, this.d, this.ty].join(', '),
      '0, 0, 1',
    ].join('), (')})`;
  }

  toCSS(): string {
    return `matrix(${this.a}, ${this.b}, ${this.c}, ${this.d}, ${this.tx}, ${this.ty})`;
  }
}

export class Vector {
  readonly x: number;

  readonly y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  add(v: Vector): Vector {
    return new Vector(this.x + v.x, this.y + v.y);
  }

  subtract(v: Vector): Vector {
    return new Vector(this.x - v.x, this.y - v.y);
  }

  get magnitude(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  get angle(): number {
    return (Math.atan2(this.y, this.x) * 180) / Math.PI;
  }

  resizeTo(magnitude: number): Vector {
    const m0 = this.magnitude;

    return new Vector((this.x * magnitude) / m0, (this.y * magnitude) / m0);
  }

  multiplyBy(m: Matrix): Vector;

  multiplyBy(n: number): Vector;

  multiplyBy(v: Matrix | number): Vector {
    if (v instanceof Matrix) {
      return new Vector(v.a * this.x + v.c * this.y + v.tx, v.b * this.x + v.d * this.y + v.ty);
    }
    return new Vector(v * this.x, v * this.y);
  }

  toString(): string {
    return `(${[this.x, this.y, 1].join(', ')})`;
  }
}

export function vector(x: number, y: number): Vector {
  return new Vector(x, y);
}

export function translate(v: Vector): Matrix;
export function translate(x: number, y?: number): Matrix;
export function translate(x: Vector | number, y?: number): Matrix {
  if (x instanceof Vector) {
    return new Matrix(1, 0, 0, 1, x.x, x.y);
  } else {
    return new Matrix(1, 0, 0, 1, x, typeof y === 'number' ? y : x);
  }
}

export function rotate(a: number, origin?: Vector): Matrix {
  const rad = (a * Math.PI) / 180;
  const sin = Math.sin(rad);
  const cos = Math.cos(rad);
  const rot = new Matrix(cos, sin, -sin, cos, 0, 0);

  if (origin) {
    return translate(origin)
      .multiplyBy(rot)
      .multiplyBy(translate(origin.multiplyBy(-1)));
  }

  return rot;
}

export function scale(a: number, b: number = a): Matrix {
  return new Matrix(a, 0, 0, b, 0, 0);
}

const unityMatrix = new Matrix(1, 0, 0, 1, 0, 0);
const zeroVector = new Vector(0, 0);

export function unity(): Matrix {
  return unityMatrix;
}

export function zero(): Vector {
  return zeroVector;
}
